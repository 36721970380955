import * as CKEditor from './ckeditor5/ckeditor5';
const ClassicEditor = CKEditor.ClassicEditor;
let CKEditorPlugins: any = [];
const Allowed: any = ["AccessibilityHelp", "Alignment", "Autoformat", "AutoImage", "Autosave", "BlockQuote", "Bold", "CloudServices", "Code", "CodeBlock", "Essentials", "FindAndReplace", "FontBackgroundColor", "FontColor", "FontFamily", "FontSize", "GeneralHtmlSupport", "Heading", "Highlight", "HorizontalLine", "ImageBlock", "ImageCaption", "ImageInline", "ImageInsert", "ImageInsertViaUrl", "ImageResize", "ImageStyle", "ImageTextAlternative", "ImageToolbar", "ImageUpload", "Indent", "IndentBlock", "Italic", "Link", "LinkImage", "List", "ListProperties", "Markdown", "MediaEmbed", "Paragraph", "PasteFromMarkdownExperimental", "PasteFromOffice", "RemoveFormat", "SelectAll", "SimpleUploadAdapter", "SpecialCharacters", "SpecialCharactersArrows", "SpecialCharactersCurrency", "SpecialCharactersEssentials", "SpecialCharactersLatin", "SpecialCharactersMathematical", "SpecialCharactersText", "Strikethrough", "Style", "Subscript", "Superscript", "Table", "TableCaption", "TableCellProperties", "TableColumnResize", "TableProperties", "TableToolbar", "TextTransformation", "TodoList", "Underline", "Undo"];
for (let key in CKEditor) {
    if (key == "ClassicEditor") continue;
    if (!CKEditor[key].pluginName) continue;
    if (Allowed.includes(CKEditor[key].pluginName))
        CKEditorPlugins.push(CKEditor[key]);
}

export {ClassicEditor, CKEditorPlugins};
