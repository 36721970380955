import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.user.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-user-list',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/portal.lpsysadmin.user.list/view.scss */
/* src/app/portal.lpsysadmin.user.list/view.scss: no such file or directory */`],
})
export class PortalLpsysadminUserListComponent implements OnInit {
    @Input() view: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        if (this.view == "admin") await this.service.auth.allow.membership('admin', "/landing");
        else await this.service.auth.allow(true, "/landing");
        await this.load();
    }

    public loading: boolean = false;
    public list: any = [];
    public target_user: any = null;

    public search: any = {
        showAdmin: false,
        order: "join_count",
        text: "",
        page: 1,
        dump: 30
    }

    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public keyuptime: any = 0

    public userEvent: any = {
        close: async () => {
            this.target_user = null;
            await this.service.render();
        }
    }

    public async searchAction() {
        let keyuptime: any = new Date().getTime();
        this.keyuptime = keyuptime;
        await this.service.render(500);
        if (this.keyuptime != keyuptime) return;
        await this.load();
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.search.page = page;
        this.list = [];
        await this.service.render();

        const { code, data } = await wiz.call("search", this.search);

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }

    public async removeItem(item) {
        let res = await this.service.alert.show({ title: "조합원 삭제", message: "조합원을 삭제하시겠습니까?", action: "삭제", cancel: "취소", actionBtn: 'error', status: 'error' });
        if (!res) return;
        const { code } = await wiz.call("remove", item);
        if (code !== 200) return this.service.alert.error("조합원 삭제 실패");
        await this.load(this.pagination.current);
    }

    public async clickUser(user) {
        this.target_user = null;
        await this.service.render();
        this.target_user = user.id;
        await this.service.render();
    }

    public async switchUser(user) {
        await wiz.call("switch", { user_id: user.id });
        await this.service.alert.success("switched");
        await this.service.render();
    }

    public async sync() {
        await this.service.loading.show();
        await wiz.call("sync");
        await this.service.loading.hide();
    }
}

export default PortalLpsysadminUserListComponent;