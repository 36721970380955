import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.fund.member.sell');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-fund-member-sell',
template: templateSource || '',
    styles: [`

/* file: /opt/lpsys/project/main/build/src/app/portal.lpsysadmin.widget.fund.member.sell/view.scss */
/* src/app/portal.lpsysadmin.widget.fund.member.sell/view.scss: no such file or directory */`],
})
export class PortalLpsysadminWidgetFundMemberSellComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund: any;

    public members: any = [];
    public investments: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("list", { id: this.fund.id });
        this.members = data.members;
        this.investments = data.investments;

        for (let i = 0; i < this.members.length; i++) {
            let member: any = this.members[i];
            if (!member.extra.sell) member.extra.sell = {};
            if (!member.extra.buy) member.extra.buy = {};

            for (let j = 0; j < this.investments.length; j++) {
                let invest: any = this.investments[j];
                if (!member.extra.buy[invest.id]) {
                    let ratio = invest.investment_amount / this.fund.total_amount;
                    let total = member.applied_amount * this.fund.amount_per_share;
                    total = Math.round(total * ratio);
                    member.extra.buy[invest.id] = total;
                }
                if (!member.extra.sell[invest.id]) {
                    member.extra.sell[invest.id] = [{}, {}, {}, {}, {}];
                }
            }
        }

        await this.service.render();
    }

    public async update() {
        const { code } = await wiz.call("update", { id: this.fund.id, data: JSON.stringify(this.members) });
        if (code == 200)
            await this.service.alert.success("저장되었습니다.");
        else
            await this.service.alert.error("오류가 발생했습니다.");
        await this.service.render();
    }

    public async alert(message: string, action: string = '확인', cancel: any = false, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public tab: any = 0;

    public async switchTab(tab: any) {
        this.tab = tab;
        await this.service.render();
    }

    public activeTab(tab: any) {
        if (this.tab == tab) return 'cursor-pointer rounded-md bg-gray-200 px-3 py-2 text-sm font-medium text-gray-800 flex items-center';
        return 'cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 flex items-center';
    }

    public sum(item) {
        let sum = 0;
        for (let i = 0; i < item.length; i++) {
            if (item[i].amount)
                sum = sum + item[i].amount * 1;
        }
        return sum;
    }

    public sumMember(mode = 'total', target = 0) {
        let sum = 0;
        for (let i = 0; i < this.members.length; i++) {
            let member = this.members[i];

            if (mode == 'total') {
                for (let investment in member.extra.sell) {
                    if (target == 'all') {
                        sum += this.sum(member.extra.sell[investment]);
                    } else if (member.extra.sell[investment][target].amount) {
                        sum += member.extra.sell[investment][target].amount * 1;
                    }
                }
            } else {
                if (member.extra.sell[mode]) {
                    if (target == 'all') {
                        sum += this.sum(member.extra.sell[mode]);
                    } else if (member.extra.sell[mode][target].amount) {
                        sum += member.extra.sell[mode][target].amount * 1;
                    }
                }
            }
        }
        return this.service.formatter.currency(sum);
    }

    public target_user: any = null;

    public async clickUser(user) {
        this.target_user = null;
        await this.service.render();
        this.target_user = user.id;
        await this.service.render();
    }

    public userEvent: any = {
        close: async () => {
            this.target_user = null;
            await this.service.render();
        }
    }

}

export default PortalLpsysadminWidgetFundMemberSellComponent;